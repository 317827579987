<div class="card card-custom gutter-b">
  <ng-container *ngIf="requesting">
    <div class="progress progress-modal">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar"
        style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </ng-container>

  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        <ng-container *ngIf="model">
          {{ (id ? 'COMMON.EDIT' : 'COMMON.NEW') | translate }}
          <span *ngIf="model && model.id">{{ model.id }} - {{ model.name }} </span>
        </ng-container>
      </h3>
    </div>
    <div class="card-toolbar">
      <a type="button" class="btn btn-light" routerLink="/functionarys">
        <i class="fa fa-arrow-left"></i>{{ 'COMMON.BACK' | translate }}
      </a>
      <button class="btn btn-secondary ml-2" (click)="reset()">
        <i class="fa fa-redo"></i>{{ 'COMMON.RESET' | translate }}
      </button>
    </div>
  </div>
  <div class="card-body">
    <ul class="nav nav-tabs nav-tabs-line" role="tablist">
      <li class="nav-item cursor-pointer" (click)="changeTab(tabs.BASIC_TAB)">
        <a class="nav-link" data-toggle="tab" role="tab" [attr.aria-selected]="activeTabId === tabs.BASIC_TAB"
          [class.active]="activeTabId === tabs.BASIC_TAB">
          {{ 'COMMON.BASIC_INFO' | translate }}
        </a>
      </li>
      <!-- <li *ngIf="model && model.id" class="nav-item cursor-pointer" (click)="changeTab(tabs.PROFILE)">
        <a class="nav-link" data-toggle="tab" role="button" [attr.aria-selected]="activeTabId === tabs.PROFILE"
          [class.active]="activeTabId === tabs.PROFILE">Profile</a>
      </li> -->
    </ul>

    <ng-container *ngIf="activeTabId === tabs.BASIC_TAB">
      <div class="mt-5" *ngIf="model">
        <form action="#" class="form form-label-right" [formGroup]="formGroup">
          <div class="form-group row">
            <div class="col-lg-4 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'FUNCTIONARY.NAME' | translate }}</label>
              <input type="text" class="form-control form-control-lg form-control-solid" autocomplete="off"
                name="name" [class.is-invalid]="isControlInvalid('name')"
                [class.is-valid]="isControlValid('name')" formControlName="name" />
              <div class="feedback" *ngIf="!isControlTouched('name')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'FUNCTIONARY.NAME' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('name')">{{ 'FUNCTIONARY.NAME' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'name')">{{ 'FUNCTIONARY.NAME' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'name')">{{ 'FUNCTIONARY.NAME' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'name')">{{ 'FUNCTIONARY.NAME' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-4 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'FUNCTIONARY.LAST_NAME' | translate }}</label>
              <input type="text" class="form-control form-control-lg form-control-solid" autocomplete="off"
                name="last_name" [class.is-invalid]="isControlInvalid('last_name')"
                [class.is-valid]="isControlValid('last_name')" formControlName="last_name" />
              <div class="feedback" *ngIf="!isControlTouched('last_name')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'FUNCTIONARY.LAST_NAME' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('last_name')">{{ 'FUNCTIONARY.LAST_NAME' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'last_name')">{{ 'FUNCTIONARY.LAST_NAME' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'last_name')">{{ 'FUNCTIONARY.LAST_NAME' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'last_name')">{{ 'FUNCTIONARY.LAST_NAME' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-4 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'FUNCTIONARY.IDENTIFICATION_NUMBER' | translate }}</label>
              <input type="number" class="form-control form-control-lg form-control-solid" autocomplete="off"
                name="identification_number" [class.is-invalid]="isControlInvalid('identification_number')"
                [class.is-valid]="isControlValid('identification_number')" formControlName="identification_number" />
              <div class="feedback" *ngIf="!isControlTouched('identification_number')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'FUNCTIONARY.IDENTIFICATION_NUMBER' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('identification_number')">{{ 'FUNCTIONARY.IDENTIFICATION_NUMBER' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'identification_number')">{{ 'FUNCTIONARY.IDENTIFICATION_NUMBER' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'identification_number')">{{ 'FUNCTIONARY.IDENTIFICATION_NUMBER' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'identification_number')">{{ 'FUNCTIONARY.IDENTIFICATION_NUMBER' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-4 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'FUNCTIONARY.PHONE' | translate }}</label>
              <input type="number" class="form-control form-control-lg form-control-solid" autocomplete="off"
                name="phone" [class.is-invalid]="isControlInvalid('phone')"
                [class.is-valid]="isControlValid('phone')" formControlName="phone" />
              <div class="feedback" *ngIf="!isControlTouched('phone')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'FUNCTIONARY.PHONE' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('phone')">{{ 'FUNCTIONARY.PHONE' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'phone')">{{ 'FUNCTIONARY.PHONE' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'phone')">{{ 'FUNCTIONARY.PHONE' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'phone')">{{ 'FUNCTIONARY.PHONE' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-4 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'FUNCTIONARY.MOBILE' | translate }}</label>
              <input type="number" class="form-control form-control-lg form-control-solid" autocomplete="off"
                name="mobile" [class.is-invalid]="isControlInvalid('mobile')"
                [class.is-valid]="isControlValid('mobile')" formControlName="mobile" />
              <div class="feedback" *ngIf="!isControlTouched('mobile')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'FUNCTIONARY.MOBILE' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('mobile')">{{ 'FUNCTIONARY.MOBILE' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'mobile')">{{ 'FUNCTIONARY.MOBILE' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'mobile')">{{ 'FUNCTIONARY.MOBILE' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'mobile')">{{ 'FUNCTIONARY.MOBILE' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-4 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'FUNCTIONARY.ADDRESS' | translate }}</label>
              <input type="text" class="form-control form-control-lg form-control-solid" autocomplete="off"
                name="address" [class.is-invalid]="isControlInvalid('address')"
                [class.is-valid]="isControlValid('address')" formControlName="address" />
              <div class="feedback" *ngIf="!isControlTouched('address')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'FUNCTIONARY.ADDRESS' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('address')">{{ 'FUNCTIONARY.ADDRESS' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'address')">{{ 'FUNCTIONARY.ADDRESS' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'address')">{{ 'FUNCTIONARY.ADDRESS' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'address')">{{ 'FUNCTIONARY.ADDRESS' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-4 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'FUNCTIONARY.INSTITUTIONAL_EMAIL' | translate }}</label>
              <input type="email" class="form-control form-control-lg form-control-solid" autocomplete="off"
                name="institutional_email" [class.is-invalid]="isControlInvalid('institutional_email')"
                [class.is-valid]="isControlValid('institutional_email')" formControlName="institutional_email" />
              <div class="feedback" *ngIf="!isControlTouched('institutional_email')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'FUNCTIONARY.INSTITUTIONAL_EMAIL' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('institutional_email')">{{ 'FUNCTIONARY.INSTITUTIONAL_EMAIL' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'institutional_email')">{{ 'FUNCTIONARY.INSTITUTIONAL_EMAIL' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'institutional_email')">{{ 'FUNCTIONARY.INSTITUTIONAL_EMAIL' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'institutional_email')">{{ 'FUNCTIONARY.INSTITUTIONAL_EMAIL' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-4 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'FUNCTIONARY.PERSONAL_EMAIL' | translate }}</label>
              <input type="email" class="form-control form-control-lg form-control-solid" autocomplete="off"
                name="personal_email" [class.is-invalid]="isControlInvalid('personal_email')"
                [class.is-valid]="isControlValid('personal_email')" formControlName="personal_email" />
              <div class="feedback" *ngIf="!isControlTouched('personal_email')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'FUNCTIONARY.PERSONAL_EMAIL' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('personal_email')">{{ 'FUNCTIONARY.PERSONAL_EMAIL' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'personal_email')">{{ 'FUNCTIONARY.PERSONAL_EMAIL' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'personal_email')">{{ 'FUNCTIONARY.PERSONAL_EMAIL' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'personal_email')">{{ 'FUNCTIONARY.PERSONAL_EMAIL' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-4 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'FUNCTIONARY.BIRTH_DATE' | translate}}</label>
              <p-calendar [inputStyleClass]="getValidClass(isControlValid('birth_date'))" name="birth_date" formControlName="birth_date"></p-calendar>
              <div class="feedback" *ngIf="!isControlTouched('birth_date')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'FUNCTIONARY.BIRTH_DATE' | translate}}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('birth_date')">{{ 'FUNCTIONARY.BIRTH_DATE' | translate}}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'birth_date')">{{ 'FUNCTIONARY.BIRTH_DATE' | translate}}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'birth_date')">{{ 'FUNCTIONARY.BIRTH_DATE' | translate}} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'birth_date')">{{ 'FUNCTIONARY.BIRTH_DATE' | translate}} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>   
            <div class="col-lg-4 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'FUNCTIONARY.PROFESSION' | translate }}</label>
              <input type="text" class="form-control form-control-lg form-control-solid" autocomplete="off"
                name="profession" [class.is-invalid]="isControlInvalid('profession')"
                [class.is-valid]="isControlValid('profession')" formControlName="profession" />
              <div class="feedback" *ngIf="!isControlTouched('profession')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'FUNCTIONARY.PROFESSION' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('profession')">{{ 'FUNCTIONARY.PROFESSION' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'profession')">{{ 'FUNCTIONARY.PROFESSION' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'profession')">{{ 'FUNCTIONARY.PROFESSION' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'profession')">{{ 'FUNCTIONARY.PROFESSION' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-4 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'FUNCTIONARY.ACTIVE' | translate}}</label>
              <p-toggleButton class="form-control form-control-lg form-control-solid" formControlName="active" onLabel="{{ 'COMMON.YES' | translate}}" offLabel="{{ 'COMMON.NO' | translate}}"></p-toggleButton>
              <div class="feedback" *ngIf="!isControlTouched('active')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'FUNCTIONARY.ACTIVE' | translate}}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('active')">{{ 'FUNCTIONARY.ACTIVE' | translate}}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'active')">{{ 'FUNCTIONARY.ACTIVE' | translate}}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'active')">{{ 'FUNCTIONARY.ACTIVE' | translate}} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'active')">{{ 'FUNCTIONARY.ACTIVE' | translate}} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-4 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'FUNCTIONARY.USER' | translate }}</label>
              <app-user-autocomplete
              placeholder="{{ 'FUNCTIONARY.USER' | translate }}" name="user" [valid]="isControlValid('user')" [touched]="isControlTouched('user')" [class.is-invalid]="isControlInvalid('user')"
              [class.is-valid]="isControlValid('user')" formControlName="user" id="user" [model]="model.user"></app-user-autocomplete>
              <div class="feedback" *ngIf="!isControlTouched('user')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'FUNCTIONARY.USER' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('user')">{{ 'FUNCTIONARY.USER' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'user')">{{ 'FUNCTIONARY.USER' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'user')">{{ 'FUNCTIONARY.USER' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'user')">{{ 'FUNCTIONARY.USER' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 30</div>
            </div>
          </div>
          <div class="float-right">
            <a class="btn btn-default font-weight-bold mr-2" routerLink="/functionarys">{{ 'COMMON.CANCEL' | translate }}</a>
            <button type="submit" class="btn btn-primary font-weight-bold mr-2"
              [disabled]="formGroup.invalid" (click)="save(false)">{{ 'COMMON.SAVE' | translate }}</button>
            <button type="submit" class="btn btn-primary font-weight-bold mr-2"
              [disabled]="formGroup.invalid" (click)="save(true)">{{ 'COMMON.SAVE_AND_EXIT' | translate }}</button>
          </div>
        </form>
      </div>
    </ng-container>
  </div>
</div>