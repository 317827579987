import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
})
export class SpaceComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
