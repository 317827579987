<div class="card card-custom gutter-b">
  <ng-container *ngIf="requesting">
    <div class="progress progress-modal">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar"
        style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </ng-container>

  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        <ng-container *ngIf="model">
          {{ (id ? 'COMMON.EDIT' : 'COMMON.NEW') | translate }}
          <span *ngIf="model && model.id">{{ 'USER.USER' | translate }} : {{ model.id }}</span>
        </ng-container>
        {{ model?.email }}
      </h3>
    </div>
    <div class="card-toolbar">
      <a type="button" class="btn btn-light" routerLink="/users">
        <i class="fa fa-arrow-left"></i>{{ 'COMMON.BACK' | translate }}
      </a>
      <button class="btn btn-secondary ml-2" (click)="reset()">
        <i class="fa fa-redo"></i>{{ 'COMMON.RESET' | translate }}
      </button>
    </div>
  </div>
  <div class="card-body">
    <ul class="nav nav-tabs nav-tabs-line" role="tablist">
      <li class="nav-item cursor-pointer" (click)="changeTab(tabs.BASIC_TAB)">
        <a class="nav-link" data-toggle="tab" role="tab" [attr.aria-selected]="activeTabId === tabs.BASIC_TAB"
          [class.active]="activeTabId === tabs.BASIC_TAB">
          {{ 'COMMON.BASIC_INFO' | translate }}
        </a>
      </li>
      <!-- <li *ngIf="model && model.id" class="nav-item cursor-pointer" (click)="changeTab(tabs.PROFILE)">
        <a class="nav-link" data-toggle="tab" role="button" [attr.aria-selected]="activeTabId === tabs.PROFILE"
          [class.active]="activeTabId === tabs.PROFILE">Profile</a>
      </li> -->
    </ul>

    <ng-container *ngIf="activeTabId === tabs.BASIC_TAB">
      <div class="mt-5" *ngIf="model">
        <form action="#" class="form form-label-right" [formGroup]="formGroup">
          <div class="form-group row">
            <div class="col-lg-4">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'USER.FIRST_NAME' | translate }}</label>
              <input type="text" class="form-control form-control-lg form-control-solid" autocomplete="off"
                placeholder="{{ 'USER.FIRST_NAME' | translate }}" name="first_name" [class.is-invalid]="isControlInvalid('first_name')"
                [class.is-valid]="isControlValid('first_name')" formControlName="first_name" />
              <div class="feedback" *ngIf="!isControlTouched('first_name')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'USER.FIRST_NAME' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('first_name')">{{ 'USER.FIRST_NAME' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'first_name')">{{ 'USER.FIRST_NAME' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'first_name')">{{ 'USER.FIRST_NAME' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'first_name')">{{ 'USER.FIRST_NAME' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-4">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'USER.LAST_NAME' | translate }}</label>
              <input type="text" class="form-control form-control-lg form-control-solid" autocomplete="off"
                placeholder="{{ 'USER.LAST_NAME' | translate }}" name="last_name" [class.is-invalid]="isControlInvalid('last_name')"
                [class.is-valid]="isControlValid('last_name')" formControlName="last_name" />
              <div class="feedback" *ngIf="!isControlTouched('last_name')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'USER.LAST_NAME' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('last_name')">{{ 'USER.LAST_NAME' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'last_name')">{{ 'USER.LAST_NAME' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'last_name')">{{ 'USER.LAST_NAME' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'last_name')">{{ 'USER.LAST_NAME' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-4">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'USER.USERNAME' | translate }}</label>
              <input type="text" class="form-control form-control-lg form-control-solid" autocomplete="off"
                placeholder="{{ 'USER.USERNAME' | translate }}" name="username" [class.is-invalid]="isControlInvalid('username')"
                [class.is-valid]="isControlValid('username')" formControlName="username" />
              <div class="feedback" *ngIf="!isControlTouched('username')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'USER.USERNAME' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('username')">{{ 'USER.USERNAME' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'username')">{{ 'USER.USERNAME' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'username')">{{ 'USER.USERNAME' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'username')">{{ 'USER.USERNAME' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-4">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'USER.EMAIL' | translate }}</label>
              <input type="text" class="form-control form-control-lg form-control-solid" autocomplete="off"
                placeholder="{{ 'USER.EMAIL' | translate }}" name="email" [class.is-invalid]="isControlInvalid('email')"
                [class.is-valid]="isControlValid('email')" formControlName="email" />
              <div class="feedback" *ngIf="!isControlTouched('email')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'USER.EMAIL' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('email')">{{ 'USER.EMAIL' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'email')">{{ 'USER.EMAIL' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'email')">{{ 'USER.EMAIL' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'email')">{{ 'USER.EMAIL' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-4">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'USER.GROUPS' | translate }}</label>
              <app-group-multiselect
              name="groups" [valid]="isControlValid('groups')" [touched]="isControlTouched('groups')" [class.is-invalid]="isControlInvalid('groups')"
              [class.is-valid]="isControlValid('groups')" formControlName="groups" id="groups" [model]="model.groups"></app-group-multiselect>
              <div class="feedback" *ngIf="!isControlTouched('groups')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'USER.GROUPS' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('groups')">{{ 'USER.GROUPS' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'groups')">{{ 'USER.GROUPS' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'groups')">{{ 'USER.GROUPS' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'groups')">{{ 'USER.GROUPS' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 30</div>
            </div>
            <div class="col-lg-4">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'USER.PERMISSIONS' | translate }}</label>
              <app-permission-multiselect
              name="user_permissions" [valid]="isControlValid('user_permissions')" [touched]="isControlTouched('user_permissions')" [class.is-invalid]="isControlInvalid('user_permissions')"
              [class.is-valid]="isControlValid('user_permissions')" formControlName="user_permissions" id="user_permissions" [model]="model.user_permissions"></app-permission-multiselect>
              <div class="feedback" *ngIf="!isControlTouched('user_permissions')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'USER.PERMISSIONS' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('user_permissions')">{{ 'USER.PERMISSIONS' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'user_permissions')">{{ 'USER.PERMISSIONS' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'user_permissions')">{{ 'USER.PERMISSIONS' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'user_permissions')">{{ 'USER.PERMISSIONS' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 30</div>
            </div>
            <div class="col-lg-4">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'USER.PASSWORD' | translate }}</label>
              <input type="password" class="form-control form-control-lg form-control-solid" autocomplete="off"
                placeholder="{{ 'USER.PASSWORD' | translate }}" name="password" [class.is-invalid]="isControlInvalid('password')"
                [class.is-valid]="isControlValid('password')" formControlName="password" />
              <div class="feedback" *ngIf="!isControlTouched('password')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'USER.PASSWORD' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('password')">{{ 'USER.PASSWORD' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'password')">{{ 'USER.PASSWORD' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'password')">{{ 'USER.PASSWORD' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'password')">{{ 'USER.PASSWORD' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-2">
              <label>{{ 'USER.IS_ACTIVATE' | translate }}</label>
              <p-toggleButton class="form-control form-control-lg form-control-solid" formControlName="is_active" onLabel="{{ 'COMMON.YES' | translate}}" offLabel="{{ 'COMMON.NO' | translate}}"></p-toggleButton>
            </div>
            <div class="col-lg-2">
              <label>{{ 'USER.IS_STAFF' | translate }}</label>
              <p-toggleButton class="form-control form-control-lg form-control-solid" formControlName="is_staff" onLabel="{{ 'COMMON.YES' | translate}}" offLabel="{{ 'COMMON.NO' | translate}}"></p-toggleButton>
            </div>
            <div class="col-lg-2">
              <label>{{ 'USER.IS_SUPERUSER' | translate }}</label>
              <p-toggleButton class="form-control form-control-lg form-control-solid" formControlName="is_superuser" onLabel="{{ 'COMMON.YES' | translate}}" offLabel="{{ 'COMMON.NO' | translate}}"></p-toggleButton>
            </div>
          </div>
          <div class="float-right">
            <a class="btn btn-default font-weight-bold mr-2" routerLink="/users">{{ 'COMMON.CANCEL' | translate }}</a>
            <button type="submit" class="btn btn-primary font-weight-bold mr-2"
              [disabled]="formGroup.invalid" (click)="save(false)">{{ 'COMMON.SAVE' | translate }}</button>
            <button type="submit" class="btn btn-primary font-weight-bold mr-2"
              [disabled]="formGroup.invalid" (click)="save(true)">{{ 'COMMON.SAVE_AND_EXIT' | translate }}</button>
          </div>
        </form>
      </div>
    </ng-container>

    <ng-container *ngIf="activeTabId === tabs.PROFILE && model && model.id">
      Profile
    </ng-container>

  </div>
</div>